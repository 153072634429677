<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>教务数据分析</a-breadcrumb-item>
            <a-breadcrumb-item>排课统计</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                      <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            
                            :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>

                     <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>上课校区</span>
                        </template>
                        <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>

                    <a-form-item>
                        <a-button @click="toScreen" :loading="loading" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="dashboard bk-group">
                <statistics :count='counts' :col="3"/>
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="24">
                        <analysisEdu :loading='loadingCH'  :leads_data ='teacherCHData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    <a-col :lg="24" :xl="24">
                      <heatmap :loading="loadingHT" :item="heatmapData" title="学员上课热点图" :start_date='paramsData.start_date' :end_date='paramsData.end_date'  />
                    </a-col>
                    <a-col :lg="24" :xl="24">
                      <a-card size="small" title="老师到课学员统计">
                        <a-button slot="extra" size="small" icon="export">导出</a-button>
                        <vue-element-loading :active="loadingTS" color="#00cca2" spinner="spinner"/>
                        <a-table size="small" :pagination="false" :bordered='false' rowKey="employee_id"
                        :columns="col_teacher_book_columns" :dataSource="col_teacher_book" :scroll="{ x: RelativeWidth, y:500}">
                        </a-table>
                      </a-card>
                    </a-col>

                    <a-col :lg="24" :xl="24">
                      <a-card size="small" title="校区到课学员统计">
                        <a-button slot="extra" size="small" icon="export">导出</a-button>
                        <vue-element-loading :active="loadingTS" color="#00cca2" spinner="spinner"/>
                        <a-table size="small" :pagination="false" :bordered='false' rowKey="studio_id"
                        :columns="col_studio_book_columns" :dataSource="col_studio_book" :scroll="{ x: RelativeWidth, y:500}">
                        </a-table>
                      </a-card> 
                    </a-col>

                    <!-- <a-col :lg="24" :xl="24">
                      <a-card size="small" title="校区到课学员统计">
                        <a-button slot="extra" size="small" icon="export">导出</a-button>
                        <vue-element-loading :active="loadingTS" color="#00cca2" spinner="spinner"/>
                        <a-table size="small" :pagination="false" :bordered='false' rowKey="studio_id"
                        :columns="col_studio_book_columns" :dataSource="col_studio_book" :scroll="{ x: RelativeWidth, y:500}">
                        </a-table>
                      </a-card> 
                    </a-col> -->

                    <a-col :lg="24" :xl="12">
                        <teacher :loading='loading' :item ='teacherData' :start_date='paramsData.start_date' :end_date='paramsData.end_date'/>
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <teacherCourse :loading='loadingC' :item ='teacherCData' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <LRanking title="校区排课统计" :rank_data="studioCourses" :loading="loadingTS" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <LRanking title="校区上课学员统计" :rank_data="studioStudents" :loading="loadingTS" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>

                    <a-col :lg="24" :xl="12">
                        <LRanking title="老师排课数据统计" :rank_data="teacherSData" :loading="loadingS" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                    <a-col :lg="24" :xl="12">
                        <LRanking title="老师授课学员统计" :rank_data="teacherBData" :loading="loadingB" :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import teacher from './teacher'
import teacherCourse from './teacherCourse'
import heatmap from './heatmap'
import analysisEdu from './analysisEdu'
import statistics from '@/views/bi/ranking/money/statistics'
export default {
  name: 'lssjtj',
  components:{
    heatmap,
    teacher,
    teacherCourse,
    analysisEdu,
    statistics
  },
  data() {
    return {
      loading:false,
      loadingB:false,
      loadingC:false,
      loadingS:false,
      loadingCH:false,
      loadingHT:false,
      loadingTS:false,
      teacherData:{},
      col_teacher_book:[],
      col_teacher_book_columns:[],
      col_studio_book:[],
      col_studio_book_columns:[],
      heatmapData:{},
      teacherCHData:{},
      teacherBData:{},
      teacherCData:{},
      teacherSData:{},
      studioCourses:{},
      studioStudents:{},
      studios:[],
      counts:[],
      paramsData:{
        start_date:moment().startOf('month').format('YYYY-MM-DD'),
        end_date:moment().format('YYYY-MM-DD'),
        studio_id:'',
        filter_type:'day',
      },
    }
  },
  mixins: [ranges],
  computed: {
      RelativeWidth() {
          let width = 850
          if(this.filedLength >10){
          width = 900 + ( this.filedLength - 10 ) * 130
          }
          return width
      }
  },
  created() {
    this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
    this.toScreen()
    this.getStudio()
  },
  methods: {
    moment,
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    getList(obj){
      this.getCount(obj)
      this.getTeacherChart(obj)
      this.getTeacher(obj)
      this.getHeatmap(obj)
      this.getTeacherSummate(obj)
      this.getTeacherCourse(obj)
      this.getTeacherBook(obj)
      this.getTeacherStudio(obj)
    },
    async getTeacher(obj){
      this.loading = true
      let res = await this.$store.dispatch('analysisTeacherAction', obj)
      this.teacherData = res.data
      this.loading = false
    },
    async getTeacherSummate(obj){
      this.loadingS = true
      let res = await this.$store.dispatch('analysisTeacherSummateAction', obj)
      this.teacherSData = res.data
      this.loadingS = false
    },
    async getTeacherCourse(obj){
      this.loadingC = true
      let res = await this.$store.dispatch('analysisTeacherCourseAction', obj)
      this.teacherCData = res.data
      this.loadingC = false
    },
    async getTeacherBook(obj){
      this.loadingB = true
      let res = await this.$store.dispatch('analysisTeacherBookAction', obj)
      this.teacherBData = res.data.book

      let single_col_date = 
       [
          { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
          { title: '员工名称', width:'160px', dataIndex: 'employee_name',  key: 'employee_name', fixed: 'left',ellipsis:true},
          { title: '合计', width:'120px', dataIndex: 'employee_book_total', key: 'employee_book_total',fixed: 'left',align:'right'},
      ]
      if(res.data.employee_book_table){
          res.data.employee_book_table.col_date.map(item=>{
              let obj = { title: item, dataIndex: item, key: item,align:'right',width:'90px'}
              single_col_date.push(obj)
          })
      }

      this.col_teacher_book_columns=single_col_date;
      this.col_teacher_book = res.data.employee_book_table.rows;


      let studio_col_date = 
       [
          { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
          { title: '校区名称', width:'160px', dataIndex: 'studio_name',  key: 'studio_name', fixed: 'left',ellipsis:true},
          { title: '合计', width:'120px', dataIndex: 'studio_book_total', key: 'studio_book_total',fixed: 'left',align:'right'},
      ]
      if(res.data.studio_book_table){
          res.data.studio_book_table.col_date.map(item=>{
              let obj = { title: item, dataIndex: item, key: item,align:'right',width:'90px'}
              studio_col_date.push(obj)
          })
      }

      let stock_col_date = [
        { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
        { title: '校区名称', width:'160px', dataIndex: 'studio_name',  key: 'studio_name', fixed: 'left',ellipsis:true},
      ];

      if(res.data.studio_book_table){
          res.data.studio_book_table.col_date.map(item=>{
              let obj = { title: item, dataIndex: item, key: item,align:'right',width:'90px'}
              stock_col_date.push(obj)
          })
      }

      this.col_studio_book_columns=stock_col_date;
      this.col_studio_book = res.data.studio_book_table.rows;
      this.loadingB = false


    },
    async getTeacherChart(obj){
      this.loadingCH = true
      let res = await this.$store.dispatch('analysisTeacherChartAction', obj)
      this.teacherCHData = res.data
      this.loadingCH = false
    },
    async getTeacherStudio(obj){
      this.loadingTS = true
      let res = await this.$store.dispatch('analysisTeacherStudioAction', obj)
      this.studioCourses = res.data.courses
      this.studioStudents = res.data.students
      this.loadingTS = false
    },
    async getHeatmap(obj){
      this.loadingHT = true
      let res = await this.$store.dispatch('analysisTeacherHeatmapAction', obj)
      this.heatmapData = res.data
      this.loadingHT = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async getCount(obj){
      let res = await this.$store.dispatch('analysisTeacherCountAction', obj)
      this.counts = res.data
    },
    handleButChange(e){
      this.paramsData.filter_type = e.target.value
      this.toScreen()
    },
    handleChange(value){
      this.paramsData.studio_id = value;
    },
    toScreen(){
      let obj = {
        search:this.paramsData
      }
      this.getList(obj);
    }
  }
}
</script>